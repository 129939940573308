import {CreditlineItem} from './CreditLineItem';

export const CreditAdviceStatus = {
  CREATING: 'CREATING',
  PRELIMINARY: 'PRELIMINARY',
  SHIPPABLE: 'SHIPPABLE',
  BILLABLE: 'BILLABLE',
  CLEARED: 'CLEARED',
};

export const CreditAdviceStatusLabels = {
  CREATING: 'erstellen',
  PRELIMINARY: 'vorläufig',
  SHIPPABLE: 'versandfertig',
  BILLABLE: 'honorarpflichtig',
  CLEARED: 'verrechnet'
};

export class CreditAdviceSummary {

  aggregateId = {
    tenantId: '',
    vendorKey: '',
    serialNumber: '',
  };
  companyName = '';
  accountingTimespan = {
    start: null,
    end: null
  };
  lastModified = null;
  lastModifiedBy = '';
  state = CreditAdviceStatus.PRELIMINARY;
  totalCount = null;
  isBatch = null;
  isItemsCountEdited;
  accountingType;
  totalSum;
  currency;


  static fromJson(payload) {
    let creditAdviceSummary = new CreditAdviceSummary();

    creditAdviceSummary.aggregateId = payload.aggregateId || {};

    creditAdviceSummary.companyName = payload.companyName || '';

    creditAdviceSummary.accountingTimespan = {
      start: new Date(payload.accountingTimespan.start) || '',
      end: new Date(payload.accountingTimespan.end) || ''
    } || {};

    creditAdviceSummary.lastModified = new Date(payload.lastModified) || '';
    creditAdviceSummary.lastModifiedBy = payload.lastModifiedBy || '';

    creditAdviceSummary.state = payload.state || CreditAdviceStatus.PRELIMINARY;

    creditAdviceSummary.totalCount = payload.totalCount || '';
    creditAdviceSummary.isBatch = payload.isBatch;
    creditAdviceSummary.isItemsCountEdited = payload.isItemsCountEdited;
    creditAdviceSummary.accountingType = payload.accountingType;
    creditAdviceSummary.totalSum = payload.totalSum;
    creditAdviceSummary.currency = payload.currency;
    return creditAdviceSummary;
  }
}

export class CreditAdvice {
  id = null;
  status = CreditAdviceStatus.PRELIMINARY;
  companyName = '';
  lastModified = null;
  lastModifiedBy = '';
  creditItems = [];
  personOfContact = '';
  accountingTimespan = {
    start: null,
    end: null
  };
  accountingType = null;

  billingTracker = {
    accountingFinishedAt: null,
    accountingNotificationSentAt: null,
    accountingNotificationTraceId: null,
    accountingRecordsArchivedAt: null,
    fileNameCsvRecord: null,
    fileNamePdfRecord: null,
    fileNameSapObjectRecords: null
  };

  isLocked() {
    return [CreditAdviceStatus.CREATING, CreditAdviceStatus.SHIPPABLE, CreditAdviceStatus.BILLABLE, CreditAdviceStatus.CLEARED].includes(this.status)
  }

  static fromJson(payload) {
    let creditAdvice = new CreditAdvice();

    creditAdvice.id = {
      serialNumber: payload.id.serialNumber,
      vendorKey: payload.id.vendorKey
    };
    creditAdvice.accountingType = payload.accountingType;
    creditAdvice.status = payload.state || CreditAdviceStatus.PRELIMINARY;

    creditAdvice.companyName = payload.companyName || '';
    creditAdvice.personOfContact = payload.personOfContact || '';
    creditAdvice.lastModified = new Date(payload.lastModified) || '';
    creditAdvice.lastModifiedBy = payload.lastModifiedBy || '';
    creditAdvice.creditItems = (payload.creditItems || []).map(i => CreditlineItem.fromJson(i));

    creditAdvice.billingTracker = payload.billingTracker || {};

    creditAdvice.accountingTimespan = {
      start: new Date(payload.accountingTimespan.start) || '',
      end: new Date(payload.accountingTimespan.end) || ''
    } || {};

    return creditAdvice;
  }

  get sum() {
    return this.creditItems.reduce((a, b) => a + b.price.value, 0);
  }
}
